<template>
  <div class="ButlerMgrForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基本信息">
          <el-form-item v-if="!isEdit" label="所属项目" :rules="[{ required: true, message: '请选择所属项目', trigger: 'blur' }]" prop="communityId">
            <v-select2 v-model="form.communityId" v-bind="communityParams" placeholder="查询项目" :width="width" @onChange="onCommunityChange" />
          </el-form-item>
          <el-form-item v-else label="所属项目">
            <div v-text="communityName"></div>
          </el-form-item>
          <el-form-item label="所属管家" :rules="[{ required: true, message: '请选择所属管家', trigger: 'blur' }]" prop="userId">
            <v-select2 v-model="form.userId" v-bind="butlerParams" placeholder="查询管家" :width="width" :handleData="handleButlerData" :subjoin="{ communityId: form.communityId }" :disabled="!form.communityId" style="display: inline;" />
            <span style="margin-left: 20px">注：所选项目下的项目账号</span>
          </el-form-item>
          <el-form-item label="服务区域" :rules="[{ required: true, message: '请选择服务区域', trigger: 'blur' }]" prop="houseIds">
            <chosen-list-panel :list.sync="form.houseIds" @select="onSelect" />
          </el-form-item>
        </col2-block>
      </col2-detail>
      <multi-select2
        ref="multiSelect"
        :searchUrl="houseSearchUrl"
        :headers="tableHeaders"
        :searchParams="searchParams"
        :responseParams="responseParams"
        :responseKey="responseKey"
        :backFill.sync="form.houseIds"
        :handleData="handleData"
        :maxCount="5000"
        :isShow.sync="visible">
          <template #searchSlot>
            <v-select label="使用状态" v-model="searchParams.usingStatus" :options="usingStatusOps"></v-select>
            <v-input label="苑" v-model="searchParams.blockName"></v-input>
            <v-input label="幢" v-model="searchParams.buildingNumber"></v-input>
            <v-input label="单元" v-model="searchParams.unit"></v-input>
            <v-input label="室" v-model="searchParams.room"></v-input>
            <v-select label="房屋类型" v-model="searchParams.houseType" :options="houseTypeOps"></v-select>
            <v-select label="房屋状态" v-model="searchParams.roomStatus" :options="roomStatusOps"></v-select>
          </template>
      </multi-select2>
    </form-panel>
  </div>
</template>

<script>
import { createURL, editURL, queryURL, getHouseListURL, getButlerListURL } from './api'
import { Col2Detail, Col2Block, MultiSelect2, ChosenListPanel } from 'components/bussiness'
import { normalCommunityParams } from 'common/select2Params'
import { houseTypeMap, roomStatusMap, roomStatusOps, usingStatusOps, usingStatusMap } from 'views/project/projectHouseConfig/validHouse/map'
import { getHouseTypeData } from 'common/networkData'

export default {
  name: 'ButlerMgrForm',
  components: {
    Col2Detail,
    Col2Block,
    MultiSelect2,
    ChosenListPanel
  },
  data () {
    return {
      width: 200,
      communityParams: normalCommunityParams,
      butlerParams: {
        searchUrl: getButlerListURL,
        request: {
          text: 'username',
          value: 'userId'
        }
      },
      form: {
        communityId: undefined,
        userId: undefined,
        houseIds: []
      },
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: ''
      },
      visible: false,
      houseSearchUrl: getHouseListURL,
      tableHeaders: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'blockName',
          label: '苑'
        },
        {
          prop: 'buildingNumber',
          label: '幢'
        },
        {
          prop: 'unit',
          label: '单元'
        },
        {
          prop: 'room',
          label: '室'
        },
        {
          prop: 'houseTypeText',
          label: '房屋类型',
          formatter (row) {
            return houseTypeMap[row.houseType]
          }
        },
        {
          prop: 'roomStatusText',
          label: '房屋状态',
          formatter: row => roomStatusMap[row.roomStatus]
        },
        {
          prop: 'statusText',
          label: '使用状态',
          formatter: row => usingStatusMap[row.status]
        }
      ],
      searchParams: {
        usingStatus: 1,
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        houseType: undefined,
        roomStatus: undefined,
        communityId: undefined
      },
      houseTypeOps: [],
      roomStatusOps: roomStatusOps(1),
      responseParams: {
        id: 'id',
        name: 'text'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      usingStatusOps: usingStatusOps(1),
      communityName: ''
    }
  },
  mounted () {
    this.fetchCommunityHouseTypes()
    const { userId, communityId } = this.$route.query
    if (userId !== undefined) {
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = editURL
      this.submitConfig.submitMethod = 'PUT'
      this.$refs.formPanel.getData({
        userId,
        communityId
      })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createURL
    }
  },
  methods: {
    update (data) {
      if (data.length) {
        this.form.communityId = data[0].communityId
        this.form.houseIds = data.map(item => {
          let arr = [item.blockName, item.buildingNumber, item.unit, item.room]
          return {
            ...item,
            text: arr.filter(val => val).join('-'),
            id: String(item.houseId)
          }
        })
        this.form.userId = data[0].userId
        this.communityName = data[0].communityName
      }
    },
    submitBefore (data) {
      data.houseIds = data.houseIds.map(item => item.id)
      return true
    },
    onSelect () {
      if (!this.form.communityId) {
        this.$message.info('请先选择项目')
        return false
      }
      this.searchParams.communityId = this.form.communityId
      this.visible = true
      this.$nextTick(() => {
        this.$refs.multiSelect.searchData()
      })
    },
    async fetchCommunityHouseTypes () {
      const { houseTypeOps } = await getHouseTypeData()
      this.houseTypeOps = houseTypeOps(1)
    },
    handleData (data) {
      // 处理结果项目显示字符
      let arr = [data.blockName, data.buildingNumber, data.unit, data.room]
      data.text = arr.filter(val => val).join('-')
    },
    handleButlerData (data) {
      // 处理管家选项显示
      return data.map(item => {
        let arr = []
        const { username, mobileNum } = item
        if (username) arr.push(username)
        if (mobileNum) arr.push(mobileNum)
        let name = arr.join('-')
        return {
          ...item,
          name
        }
      })
    },
    submitSuccess (houseIds) {
      if (houseIds) {
        let msg = this.form.houseIds.filter(item => houseIds.includes(Number(item.id))).map(item => item.text).join('、')
        this.$alert(msg, {
          type: 'warning',
          title: '以下房号已关联服务管家，请核对信息后重新设置'
        })
      } else {
        return true
      }
    },
    onCommunityChange () {
      this.form.houseIds = []
    }
  },
  computed: {
    isEdit () {
      return this.$route.query.userId
    }
  }
}
</script>
