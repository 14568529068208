// 获取列表
const getListURL = `${API_CONFIG.shopBaseURL}callButler/list`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}callButler/export`
// 查询房号
const getHouseListURL = `${API_CONFIG.shopBaseURL}communityRoom/listCommunityRoom`
// 获取房屋类型
// const getCommunityRommTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getCommunityRommTypes.action`
// 管家查询
const getButlerListURL = `${API_CONFIG.shopBaseURL}user/search/community/username`
// 删除管家
const deleteURL = `${API_CONFIG.shopBaseURL}callButler/delete`
// 新增
const createURL = `${API_CONFIG.shopBaseURL}callButler/add`
// 编辑
const editURL = `${API_CONFIG.shopBaseURL}callButler/update`
// 查询详情
const queryURL = `${API_CONFIG.shopBaseURL}callButler/view`
// 上传
const uploadURL = `${API_CONFIG.baseURL}uploadAction!upload.action?module=shopgoods`

export {
  getListURL,
  exportListURL,
  createURL,
  editURL,
  queryURL,
  uploadURL,
  getHouseListURL,
  // getCommunityRommTypesURL,
  getButlerListURL,
  deleteURL
}
