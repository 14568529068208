var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ButlerMgrForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基本信息" } },
                [
                  !_vm.isEdit
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属项目",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属项目",
                                trigger: "blur",
                              },
                            ],
                            prop: "communityId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  placeholder: "查询项目",
                                  width: _vm.width,
                                },
                                on: { onChange: _vm.onCommunityChange },
                                model: {
                                  value: _vm.form.communityId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "communityId", $$v)
                                  },
                                  expression: "form.communityId",
                                },
                              },
                              "v-select2",
                              _vm.communityParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "所属项目" } }, [
                        _c("div", {
                          domProps: { textContent: _vm._s(_vm.communityName) },
                        }),
                      ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属管家",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属管家",
                            trigger: "blur",
                          },
                        ],
                        prop: "userId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            staticStyle: { display: "inline" },
                            attrs: {
                              placeholder: "查询管家",
                              width: _vm.width,
                              handleData: _vm.handleButlerData,
                              subjoin: { communityId: _vm.form.communityId },
                              disabled: !_vm.form.communityId,
                            },
                            model: {
                              value: _vm.form.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userId", $$v)
                              },
                              expression: "form.userId",
                            },
                          },
                          "v-select2",
                          _vm.butlerParams,
                          false
                        )
                      ),
                      _c("span", { staticStyle: { "margin-left": "20px" } }, [
                        _vm._v("注：所选项目下的项目账号"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "服务区域",
                        rules: [
                          {
                            required: true,
                            message: "请选择服务区域",
                            trigger: "blur",
                          },
                        ],
                        prop: "houseIds",
                      },
                    },
                    [
                      _c("chosen-list-panel", {
                        attrs: { list: _vm.form.houseIds },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "houseIds", $event)
                          },
                          select: _vm.onSelect,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("multi-select2", {
            ref: "multiSelect",
            attrs: {
              searchUrl: _vm.houseSearchUrl,
              headers: _vm.tableHeaders,
              searchParams: _vm.searchParams,
              responseParams: _vm.responseParams,
              responseKey: _vm.responseKey,
              backFill: _vm.form.houseIds,
              handleData: _vm.handleData,
              maxCount: 5000,
              isShow: _vm.visible,
            },
            on: {
              "update:backFill": function ($event) {
                return _vm.$set(_vm.form, "houseIds", $event)
              },
              "update:back-fill": function ($event) {
                return _vm.$set(_vm.form, "houseIds", $event)
              },
              "update:isShow": function ($event) {
                _vm.visible = $event
              },
              "update:is-show": function ($event) {
                _vm.visible = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-select", {
                      attrs: { label: "使用状态", options: _vm.usingStatusOps },
                      model: {
                        value: _vm.searchParams.usingStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "usingStatus", $$v)
                        },
                        expression: "searchParams.usingStatus",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "苑" },
                      model: {
                        value: _vm.searchParams.blockName,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "blockName", $$v)
                        },
                        expression: "searchParams.blockName",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "幢" },
                      model: {
                        value: _vm.searchParams.buildingNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "buildingNumber", $$v)
                        },
                        expression: "searchParams.buildingNumber",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "单元" },
                      model: {
                        value: _vm.searchParams.unit,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "unit", $$v)
                        },
                        expression: "searchParams.unit",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "室" },
                      model: {
                        value: _vm.searchParams.room,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "room", $$v)
                        },
                        expression: "searchParams.room",
                      },
                    }),
                    _c("v-select", {
                      attrs: { label: "房屋类型", options: _vm.houseTypeOps },
                      model: {
                        value: _vm.searchParams.houseType,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "houseType", $$v)
                        },
                        expression: "searchParams.houseType",
                      },
                    }),
                    _c("v-select", {
                      attrs: { label: "房屋状态", options: _vm.roomStatusOps },
                      model: {
                        value: _vm.searchParams.roomStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "roomStatus", $$v)
                        },
                        expression: "searchParams.roomStatus",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }